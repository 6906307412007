<script setup lang="ts">
import { useBookingWidgetStore } from '@playa/store/bookingWidgetStore'
import { usePageStore } from '@voix/store/pageStore'
import { v4 as uuidv4 } from 'uuid'

defineProps<{
  menu: any
}>()

const showMobileMenu = ref(false)
const bookingWidgetStore = useBookingWidgetStore()
const pageStore = usePageStore()
const currentPage = pageStore.currentPage

const instanceId = uuidv4()
const { toggleBookingModal } = bookingWidgetStore

const enLink = computed(() => {
  if (currentPage?.language_code === 'en')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'en')
})

const esLink = computed(() => {
  if (currentPage?.language_code === 'es')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'es')
})
</script>

<template>
  <div class="absolute w-full pt-24 font-sans-montserrat text-white">
    <div class="hidden lg:block absolute z-[9999] top-0 left-0 right-0">
      <div class="relative bg-black text-white flex justify-center py-4">
        <div class="w-full flex justify-center items-center space-x-16">
          <div class="flex space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
              <path
                fill-rule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clip-rule="evenodd"
              />
            </svg>
            <a href="tel:+529985002798">(+52) 998 500 2798</a>
          </div>
          <div class="flex space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6"
            >
              <path
                stroke-linecap="round" stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>

            <a
              href="mailto:contact@infinityluxurytravelclub.com"
              target="_blank"
            >contact@infinityluxurytravelclub.com</a>
          </div>
        </div>

        <div class="xl:absolute xl:top-0 xl:bottom-0 xl:right-0 w-[300px] flex items-center space-x-6">
          <ul v-if="currentPage && currentPage.localizations.length" class="flex items-center divide-x divide-white">
            <li class="uppercase px-2">
              <NuxtLink
                :key="enLink?.path"
                :to="enLink?.path"
                :class="{ 'text-infinity-action': enLink?.language_code === currentPage.language_code }"
              >
                EN
              </NuxtLink>
            </li>
            <li class="uppercase px-2">
              <NuxtLink
                :key="esLink?.path"
                :to="esLink?.path"
                :class="{ 'text-infinity-action': esLink?.language_code === currentPage.language_code }"
              >
                ES
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex items-center justify-between container mx-auto py-4">
        <div>
          <img src="/imgs/infinity-luxury/logo.png" alt="Infinity Luxury Travel Club Logo" class="h-24">
        </div>
        <div class="hidden lg:flex items-center">
          <ul class="flex uppercase lg:space-x-8 xl:space-x-12 2xl:space-x-8">
            <li v-for="menuItem in menu?.menu_items" :key="menuItem.id">
              <a :href="menuItem.permalink">{{ menuItem.name }}</a>
            </li>
          </ul>
        </div>
        <div />
      </div>
    </div>

    <div class="lg:hidden">
      <div class="absolute z-[9999] top-0 left-0 right-0">
        <div class="flex items-center justify-between p-4 px-6">
          <button @click="showMobileMenu = true">
            <Icon name="heroicons:bars-3-20-solid" class="text-white w-8 h-8" />
          </button>
          <img src="/imgs/infinity-luxury/logo.png" class="h-24">
          <a href="tel:+529985002798">
            <Icon name="heroicons:phone-solid" class="text-white w-8 h-8" />
          </a>
        </div>
      </div>

      <div
        class="absolute z-[9999] h-[100vh] inset-0 flex flex-col space-y-16 lg:space-y-0 p-12 duration-300 ease-out bg-infinity-dark"
        :class="{ '-translate-x-full': !showMobileMenu }"
      >
        <div class="flex items-center justify-between">
          <div><img src="/imgs/infinity-luxury/logo.png" class="h-32"></div>
          <button @click="showMobileMenu = !showMobileMenu">
            <Icon name="heroicons:x-mark-20-solid" class="text-white w-12 h-12" />
          </button>
        </div>

        <ul class="uppercase space-y-4 text-xl pl-2">
          <li v-for="menuItem in menu?.menu_items" :key="menuItem.id">
            <a :href="menuItem.permalink" @click="showMobileMenu = false">{{ menuItem.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
